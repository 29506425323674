import React from 'react'
import type { FC } from 'react'
import { graphql } from 'gatsby'
import { Center, Flex, Spinner, SuspenseSSR } from '@vtex/store-ui'
import { View } from 'src/components/ui/View'
import Layout from 'src/components/Layout'
import AboveTheFold from 'src/views/quiz-pele/resultado/AboveTheFold'
import Seo from 'src/views/quiz-pele/resultado/Seo'

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
}

const Page: FC = (props) => {
  return (
    <SuspenseSSR
      fallback={
        <Flex sx={{ height: '100vh' }}>
          <Center>
            <Spinner />
          </Center>
        </Flex>
      }
    >
      <Layout>
        <View {...ViewComponents} data={props} />
      </Layout>
    </SuspenseSSR>
  )
}

export const querySSG = graphql`
  query QuizPeleResultadoQuery {
    cmsInstitutionalPage(name: { eq: "Quiz Pele Resultado" }) {
      sections {
        name
        props: data
      }
      seo {
        siteMetadataWithSlug {
          title
          description
          titleTemplate
          slug
        }
      }
    }
  }
`

export default Page
