import React from 'react'
import { Box, Flex, Text } from '@vtex/store-ui'
import { useQuery } from '@vtex/gatsby-theme-store'
import Shelf from 'src/components/product/ProductSlider'
import ProductSummary from 'src/components/product/ProductSummaryWhite'

import type {
  ProductsBySkuIdQueryQuery,
  ProductsBySkuIdQueryQueryVariables,
} from '../__generated__/ProductsBySkuIdQuery.graphql'
import { ProductsBySkuIdQuery } from '../__generated__/ProductsBySkuIdQuery.graphql'
import styles from '../styles.json'

const SuggestionsShelf = ({ suggestionItem, index }) => {
  const productId = suggestionItem.products
    .replace(/,*$/, '')
    .replace(',,', ',')
    .replace('""', '')
    .split(',')
  // eslint-disable-next-line
  const suggestionNumber = index + 1
  const { data } = useQuery<
    ProductsBySkuIdQueryQuery,
    ProductsBySkuIdQueryQueryVariables
  >({
    ...ProductsBySkuIdQuery,
    variables: { id: productId },
    revalidateOnMount: true,
  })

  return (
    <Box>
      <Box sx={styles.suggestionsContainer.wrapper}>
        <Box sx={styles.suggestionsContainer.text}>
          <Flex>
            <Text as="h2" sx={styles.suggestionsContainer.text.number}>
              {suggestionNumber}
            </Text>
            <Box sx={styles.suggestionsContainer.text.wrapper}>
              <Text as="h2" sx={styles.suggestionsContainer.text.title}>
                {suggestionItem.displayName}
              </Text>
              {suggestionItem.name === 'cravosOleosidade' && (
                <Text as="p" sx={styles.suggestionsContainer.text.description}>
                  Diariamente lave o rosto pela manhã e à noite. Até 3 vezes por
                  semana faça uma esfoliação para eliminar impurezas dos poros.
                </Text>
              )}
              {suggestionItem.name === 'skin' && (
                <Text as="p" sx={styles.suggestionsContainer.text.description}>
                  É importante você saber os produtos específicos que a sua pele
                  precisa. As suas necessidades podem mudar ao longo tempo, e
                  com make ou não, sua pele fica preparada para o dia a dia.
                </Text>
              )}
              {suggestionItem.name === 'faixaEtaria' && (
                <Text as="p" sx={styles.suggestionsContainer.text.description}>
                  É importante você saber os produtos específicos que a sua pele
                  precisa. As suas necessidades podem mudar ao longo tempo, e
                  com make ou não, sua pele fica preparada para o dia a dia.
                </Text>
              )}
              {suggestionItem.name === 'areaOlhos' && (
                <Text as="p" sx={styles.suggestionsContainer.text.description}>
                  A área dos olhos é super delicada, por isso é necessário um
                  produto específico para a região.
                </Text>
              )}
              {suggestionItem.name === 'protecaoSolar' && (
                <Text as="p" sx={styles.suggestionsContainer.text.description}>
                  Para finalizar sua rotina e estar pronta para a make, falta
                  apenas proteção solar para manter sua pele livre de danos.
                </Text>
              )}
            </Box>
          </Flex>
        </Box>
        <Box sx={styles.suggestionsContainer.shelf}>
          {data ? (
            data?.vtex.productsByIdentifier && (
              <>
                {data?.vtex.productsByIdentifier.length > 1 ? (
                  <Shelf
                    showArrows
                    products={data?.vtex.productsByIdentifier}
                    ProductSummary={ProductSummary}
                    pageSizes={[1]}
                    isStaticShelf
                  />
                ) : (
                  <Box sx={styles.suggestionsContainer.shelf.single}>
                    <Shelf
                      products={data?.vtex.productsByIdentifier}
                      ProductSummary={ProductSummary}
                      pageSizes={[1]}
                      isStaticShelf
                    />
                  </Box>
                )}
              </>
            )
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Text as="h2" sx={styles.suggestionsContainer.text.title}>
                Produto indisponível.
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default SuggestionsShelf
