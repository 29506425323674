import React from 'react'
import { Box, Text } from '@vtex/store-ui'
import { useQuery } from '@vtex/gatsby-theme-store'
import Shelf from 'src/components/product/ProductSlider'
import ProductSummary from 'src/components/product/ProductSummaryWhite'

import type {
  ProductsBySkuIdQueryQuery,
  ProductsBySkuIdQueryQueryVariables,
} from '../__generated__/ProductsBySkuIdQuery.graphql'
import { ProductsBySkuIdQuery } from '../__generated__/ProductsBySkuIdQuery.graphql'
import styles from '../styles.json'

const ComplementsShelf = ({ complementItem }) => {
  const productId = complementItem.products
    .replace(/,*$/, '')
    .replace(',,', ',')
    .replace('""', '')
    .split(',')

  const { data } = useQuery<
    ProductsBySkuIdQueryQuery,
    ProductsBySkuIdQueryQueryVariables
  >({
    ...ProductsBySkuIdQuery,
    variables: { id: productId },
    revalidateOnMount: true,
  })

  return (
    <Box>
      {data && (
        <Box sx={styles.complementsContainer.wrapper}>
          <Box sx={styles.complementsContainer.text}>
            <Text as="h2">{complementItem.complement}</Text>
            {complementItem.complement === 'Tratamento Diário' && (
              <Text as="p">
                Independente se você usa make ou não, sua pele precisa ser
                tratada diariamente. E é importante você saber os produtos
                específicos para deixar ela preparada para o dia a dia.
              </Text>
            )}
            {complementItem.complement === 'Tratamentos Específicos' && (
              <Text as="p">
                Independente se você usa make ou não, sua pele precisa ser
                tratada diariamente. E é importante você saber os produtos
                específicos para deixar ela preparada para o dia a dia.
              </Text>
            )}
            {complementItem.complement === 'Área dos Olhos' && (
              <Text as="p">
                A área dos olhos é super delicada, por isso é necessário um
                produto específico para a região.
              </Text>
            )}
          </Box>
          <Box sx={styles.complementsContainer.shelf}>
            {data?.vtex.productsByIdentifier && (
              <>
                {data?.vtex.productsByIdentifier.length > 1 ? (
                  <Shelf
                    showArrows
                    products={data?.vtex.productsByIdentifier}
                    ProductSummary={ProductSummary}
                    pageSizes={[1]}
                    isStaticShelf
                  />
                ) : (
                  <Box sx={styles.suggestionsContainer.shelf.single}>
                    <Shelf
                      products={data?.vtex.productsByIdentifier}
                      ProductSummary={ProductSummary}
                      pageSizes={[1]}
                      isStaticShelf
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ComplementsShelf
