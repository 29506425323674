/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { Box, Text } from '@vtex/store-ui'
import SuggestionsShelf from './SuggestionsShelf'
import ComplementsShelf from './ComplementsShelf'

import styles from './styles.json'

const Products = () => {
  const [suggestions, setSuggestions] = useState()
  const [complements, setComplements] = useState()
  const complementsArray: any[] | never[] = []
  const suggestionsArray: any[] | never[] = []

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    if (!searchParams) {
      navigate('/')
    }

    if (searchParams.get('questions')) {
      suggestionsArray.push(JSON.parse(searchParams.get('questions')))
      setSuggestions(suggestionsArray)
    } else {
      setSuggestions(null)
    }

    if (searchParams.get('complements')) {
      complementsArray.push(JSON.parse(searchParams.get('complements')))
      setComplements(complementsArray)
    } else {
      setComplements(null)
    }
  }, [])

  return (
    <Box sx={styles.container}>
      {suggestions && (
        <Box sx={styles.suggestionsContainer}>
          {suggestions[0].map((suggestionItem: any, index: number) => (
            <SuggestionsShelf
              key={index}
              suggestionItem={suggestionItem}
              index={index}
            />
          ))}
        </Box>
      )}
      {complements && (
        <Box sx={styles.complementsContainer}>
          <Box sx={styles.complementsContainer.title}>
            <Text as="h2">Para complementar a rotina</Text>
          </Box>
          {complements[0].map((complementItem: any, index: number) => (
            <ComplementsShelf key={index} complementItem={complementItem} />
          ))}
        </Box>
      )}
    </Box>
  )
}

export const query = graphql`
  query ProductsBySkuIdQuery($id: [ID!]!) {
    vtex {
      productsByIdentifier(field: id, values: $id) {
        ...ProductSummary_product
      }
    }
  }
`

export default Products
