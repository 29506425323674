import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, Image } from '@vtex/store-ui'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import styles from './styles.json'

const IntroBanner = ({ data }) => {
  const [userName, setUserName] = useState<string>('')
  const [ritualSteps, setRitualSteps] = useState<string>('')
  const banner = data.cmsInstitutionalPage.sections[0].props

  const { isMobile } = useDeviceDetect()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    if (searchParams.get('name')) {
      setUserName(searchParams.get('name'))
    }

    if (searchParams.get('questions')) {
      setRitualSteps(JSON.parse(searchParams.get('questions')))
    }
  }, [setUserName])

  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleContent}>
        <Text as="h1">Resultado do Quiz "Ritual de Beleza"</Text>
      </Box>
      <Flex sx={styles.bannerWrapper}>
        <Box sx={styles.bannerWrapper.text}>
          {ritualSteps && ritualSteps.length >= 2 && (
            <Text as="h2" sx={styles.bannerWrapper.text.title}>
              Os {ritualSteps.length} passos para seu ritual diário
            </Text>
          )}
          <Text as="p" sx={styles.bannerWrapper.text.userName}>
            Olá, <Text as="strong">{userName}</Text>
          </Text>

          <Text as="p" sx={styles.bannerWrapper.text.description}>
            Montamos esse ritual de beleza ideal para suas necessidades da pele
            do rosto, que variam conforme momento da vida, idade e
            característica. Siga este passo a passo para uma pele mais bonita e
            saudável.
          </Text>
        </Box>
        <Box sx={styles.bannerWrapper.image}>
          <Image
            src={isMobile ? banner.mobile.srcSet : banner.desktop.srcSet}
            alt={banner.alt}
            title={banner.title}
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default IntroBanner
