import React from 'react'
import type { FC } from 'react'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { getCookie } from 'src/utils/cookies'
import { useSiteLinksSearchBoxJsonLd } from 'src/views/home/Seo/useSiteLinksSearchBoxJsonLd'
import type { PageProps } from 'gatsby'
import type { QuizPeleResultadoQueryQuery } from 'src/pages/quiz-pele/resultado/__generated__/QuizPeleResultadoQuery.graphql'

type Props = PageProps<QuizPeleResultadoQueryQuery>

const Seo: FC<Props> = (props) => {
  const siteMetadata =
    props?.data?.cmsInstitutionalPage?.seo?.siteMetadataWithSlug

  const siteLinksSearchBox = useSiteLinksSearchBoxJsonLd(props)

  const repId = getCookie('selectedRepresentantId')

  return (
    <>
      <GatsbySeo
        title={siteMetadata?.title ?? 'Avon - Ritual de Belezaa'}
        description={siteMetadata?.description ?? 'Avon - Ritual de Belezaa'}
        titleTemplate={siteMetadata?.titleTemplate ?? '%s | Avon'}
        noindex={!!repId}
      />
      <JsonLd json={siteLinksSearchBox} defer />
    </>
  )
}

export default Seo
