import React from 'react'
import { Box } from '@vtex/store-ui'
import IntroBanner from 'src/components/quiz-pele/resultado/IntroBanner'
import Products from 'src/components/quiz-pele/resultado/Products'
import Share from 'src/components/quiz-pele/resultado/Share'

const AboveTheFold = (props) => {
  return (
    <Box>
      <IntroBanner data={props.data} />
      <Products />
      <Share />
    </Box>
  )
}

export default AboveTheFold
